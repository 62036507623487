import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilUser,
  cilLockLocked,
  cilAccountLogout,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilUser,
  cilLockLocked,
  cilAccountLogout,
})
